import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"
import { Text, Heading, Divider } from "@chakra-ui/react"
import { colors } from "../../theme/theme"

const StyledHeading = styled(Heading)`
  text-align: center;
  color: ${colors.white};
`

const StyledDiv = styled.div`
  width: 13rem;
  height: 11.5rem;
  padding: 1rem;
  border: 0;
  border-radius: 1rem;
  border-width: 2px;
  border-color: none;
  margin: 0.5rem;
  background-image: linear-gradient(
    to right,
    ${colors.blue.blue3},
    ${colors.blue.blue4}
  );
  @media (max-width: 647px) {
    width: 100%;
    height: 8.5rem;
  }
`

interface ITileProps {
  number: number
  title: string
  description: string
}

export const Tile: FunctionComponent<ITileProps> = ({
  number,
  title,
  description,
}: ITileProps) => {
  return (
    <StyledDiv>
      <StyledHeading as="h3" fontSize="1.2rem">
        {number}: {title}
      </StyledHeading>
      <Divider margin="0.25rem 0" borderColor={colors.white} />
      <Text
        style={{
          color: colors.white,
          fontSize: "1rem",
          textAlign: "center",
        }}
      >
        {description}
      </Text>
    </StyledDiv>
  )
}

export default Tile
