import React, { FunctionComponent } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
// @ts-ignore
import HeaderBanner from "../components/Hire/HeaderBanner"
import PortfolioProjects from "../components/Hire/PortfolioProjects"
import WhatsIncluded from "../components/Hire/WhatsIncluded"
import HowiWork from "../components/Hire/HowiWork"
import FourStepsTiles from "../components/Hire/FourStepsTiles"
import { Flex } from "@chakra-ui/react"
import ContactSection from "../components/LandingPage/ContactSection"
import { contactEmail } from "../utils/routes"
import { colors } from "../theme/theme"

export const Hire: FunctionComponent = ({}) => {
  return (
    <>
      <Seo
        title="Hire Me | HendrikGruber.com"
        meta={[
          {
            name: "Hire Me",
            content: "Hire Hendrik Gruber to create your website.",
          },
        ]}
      />
      <Layout>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <HeaderBanner />
          <Flex
            width="80%"
            maxWidth="40rem"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
          >
            <FourStepsTiles />
            <WhatsIncluded />
            <HowiWork />
            <PortfolioProjects />
            <ContactSection
              title="Get in Touch With Me"
              description={
                <>
                  If you're interested, then please{" "}
                  <a
                    href={"mailto:" + contactEmail}
                    style={{
                      color: colors.blue.blue3,
                      textDecoration: "underline",
                    }}
                  >
                    send me an email
                  </a>{" "}
                  in English or German or click on one of the contact buttons
                  below. I look forward to hearing from you!
                </>
              }
            />
          </Flex>
        </Flex>
      </Layout>
    </>
  )
}

export default Hire
