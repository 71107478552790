import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"
import { Box, Heading, Text } from "@chakra-ui/react"
import { colors } from "../../theme/theme"

const StyledContainer = styled.div`
  width: 100vw;
  background-image: linear-gradient(
    to right,
    ${colors.blue.blue8},
    ${colors.blue.blue6}
  );
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 2rem 0;
  margin: 1rem 0;
`

export const HowiWork: FunctionComponent = ({}) => {
  return (
    <StyledContainer>
      <Heading
        as="h2"
        textAlign="center"
        fontSize="1.75rem"
        paddingBottom="1rem"
        color={colors.grey.dark}
        fontWeight="800"
      >
        How I Work
      </Heading>
      <Box
        color={colors.grey.dark}
        fontWeight="light"
        maxW="50rem"
        padding="0 1.5rem"
        margin="0 auto"
      >
        <Text>
          The most important requirement for building a{" "}
          <b>successful website</b> is <b>close cooperation</b> with you. That
          means keeping you <b>up to date</b> on the development status and
          continuously receiving <b>feedback</b> from you.{" "}
        </Text>
        <Text marginTop="0.5rem">
          I charge a fair price per hour and can work up to 20 hours per week.
        </Text>
      </Box>
    </StyledContainer>
  )
}

export default HowiWork
