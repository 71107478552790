import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { Link } from "gatsby"
import { colors } from "../../theme/theme"
import ProjectCard from "./ProjectCard"
// @ts-ignore
import rotWeiss from "../../assets/images/projects/rw-homepage.png"

const StyledLi = styled.li`
  margin-bottom: 0;
`

const StyledContainer = styled.div`
  width: 100vw;
  text-align: center;
  padding: 2rem 0;
  margin-top: 1rem;
`

export const PortfolioProjects: FunctionComponent = ({}) => {
  return (
    <StyledContainer>
      <Heading
        as="h2"
        textAlign="center"
        fontSize="1.75rem"
        paddingBottom="1rem"
        color={colors.grey.dark}
        fontWeight="800"
      >
        Experience
      </Heading>
      <Box
        color={colors.grey.dark}
        fontWeight="light"
        maxW="50rem"
        padding="0 1.5rem"
        margin="0 auto"
      >
        <Text style={{ marginBottom: "0.75rem" }}>
          I have gained experience as a web developer by working for over 2
          years in the industry as a web developer at{" "}
          <Link
            to="https://www.qbeyond.de/"
            target="_blank"
            style={{ color: colors.blue.blue2, textDecoration: "underline" }}
          >
            q.beyond AG
          </Link>
          . On top of that, I also have a Bachelor's Degree in Computer Science.
        </Text>
        <Text style={{ marginBottom: "0.75rem" }}>
          The following is a list of projects that I've previously worked on.
        </Text>
        <Flex width="100" flexWrap="wrap" justifyContent="center">
          <ProjectCard
            title="Sports Club Homepage"
            description="A homepage for my former soccer club Rot-Weiss Darmstadt."
            image={rotWeiss}
            link="https://www.rot-weiss-darmstadt.de/"
          />
        </Flex>
      </Box>
    </StyledContainer>
  )
}

export default PortfolioProjects
