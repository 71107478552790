import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"
import { Box, Heading, Text } from "@chakra-ui/react"
// @ts-ignore
import { colors } from "../../theme/theme"

const StyledLi = styled.li`
  margin-bottom: 0;
`

const StyledContainer = styled.div`
  width: 100vw;
  text-align: center;
  padding: 2rem 0;
  margin: 1rem 0;
`

export const WhatsIncluded: FunctionComponent = ({}) => {
  return (
    <StyledContainer>
      <Heading
        as="h2"
        textAlign="center"
        fontSize="1.75rem"
        paddingBottom="1rem"
        color={colors.grey.dark}
        fontWeight="800"
      >
        What's Included?
      </Heading>
      <Box
        color={colors.grey.dark}
        fontWeight="light"
        maxW="35rem"
        minW="80%"
        padding="0 1.5rem"
        margin="0 auto"
      >
        <Text style={{ marginBottom: "0.75rem" }}>
          Everything in the following list is automatically included for your
          project.
        </Text>
        <ul style={{ listStylePosition: "inside" }}>
          <StyledLi>
            Compatibility for <b>Mobile</b> devices
          </StyledLi>
          <StyledLi>
            <b>S.E.O.</b> for better ranking on Google
          </StyledLi>
          <StyledLi>
            Support in <b>multiple languages</b> for international
            website-visitors
          </StyledLi>
          <StyledLi>
            <b>Google Analytics</b> for user statistics
          </StyledLi>
          <StyledLi>
            <b>Documentation</b> about the project
          </StyledLi>
        </ul>
        <Text style={{ marginTop: "0.75rem" }}>
          If you have any further requirements, then we can gladly discuss them.
        </Text>
      </Box>
    </StyledContainer>
  )
}

export default WhatsIncluded
