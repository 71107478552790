import React, { FunctionComponent, useState } from "react"
import { Flex, Box, Image, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { cardRadius, colors, shadows } from "../../theme/theme"
// @ts-ignore
import CutoutPicture from "../../assets/images/profile_outlined.png"
import LoadingSkeleton from "../../components/LoadingSkeleton"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import CustomButton from "../CustomButton"

const mobileSwitchNum = 720
const mobileSwitch = mobileSwitchNum + "px"
const minWidth = "600px"

const StyledBackgroundBox = styled(Box)`
  background-image: linear-gradient(
    to right,
    ${colors.blue.blue2},
    ${colors.blue.blue5}
  );
  border-radius: ${cardRadius};
  box-shadow: ${shadows.containerShadow};
  width: 100%;
  height: 25rem;
`

const StyledText = styled(Text)`
  margin: 0.5rem 0;
  line-height: 1.5rem;
  color: ${colors.white};
`

const TextContainer = styled(Box)`
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: clip;
  margin-left: 0.5rem;
  width: 50%;
  overflow: "visible" @media (max-width: ${mobileSwitch}) {
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin-top: -1rem;
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin-left: 0;
    width: 100%;
  }
`

const StyledFlex = styled(Flex)`
  width: 100%;
  padding: 0 1rem;

  @media (max-width: ${mobileSwitch}) {
    width: 80%;
  }
  @media (max-width: ${minWidth}) {
    width: 100%;
  }
`

export const HeaderBanner: FunctionComponent = ({}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  const { width } = useWindowDimensions()

  return (
    <StyledBackgroundBox>
      <StyledFlex
        alignItems="center"
        justifyContent="center"
        marginTop="2rem"
        marginBottom={width < 720 && width > 481 ? "2.5rem" : "0"}
      >
        <TextContainer>
          <StyledText
            float="right"
            height={width <= mobileSwitchNum ? "100%" : "17rem"}
            zIndex={10}
            width="9rem"
            paddingRight="1rem"
          >
            <Text width={width <= mobileSwitchNum ? "7rem" : "11rem"}>
              Hey, I'm
            </Text>
            <Text
              fontSize="1.5rem"
              color={colors.white}
              padding="0.25rem 0"
              textTransform="uppercase"
              fontWeight="bold"
              width={width <= mobileSwitchNum ? "99%" : "11rem"}
            >
              Hendrik
            </Text>
            <Text width={width <= mobileSwitchNum ? "99%" : "11rem"}>
              and I love building websites.
            </Text>
            {width <= mobileSwitchNum && (
              <>
                <Text width="99%">
                  If you need a new website for your small business, club, or
                  hobby, then let's get in touch!
                </Text>
                <CustomButton
                  text="contact"
                  gradientColors={{
                    mainLeft: colors.white,
                    mainRight: colors.white,
                    hoverLeft: colors.grey.light,
                    hoverRight: colors.grey.light,
                  }}
                  clickedHandler={() => {
                    document.getElementById("contact-section")?.scrollIntoView()
                  }}
                  styles={{ width: "90%", marginTop: "0.75rem" }}
                />
              </>
            )}
          </StyledText>
        </TextContainer>
        <Image
          src={CutoutPicture}
          title="Hendrik Gruber Cutout"
          objectFit="cover"
          height={width > mobileSwitchNum ? "23rem" : "17rem"}
          style={{ display: `${imageLoaded ? "" : "none"}` }}
          onLoad={() => {
            setImageLoaded(true)
          }}
        />

        {!imageLoaded && (
          <LoadingSkeleton
            width={width > mobileSwitchNum ? "38%" : "35%"}
            height={width > mobileSwitchNum ? "23rem" : "17rem"}
            radius="cardRadius"
            styles={{ boxShadow: shadows.containerShadow }}
          />
        )}
        {width > mobileSwitchNum && (
          <TextContainer>
            <StyledText>
              <Text width="9rem" float="left" textAlign="right">
                If you need a new website for your small business, club, or
                hobby, then let's get in touch!
                <CustomButton
                  text="contact"
                  gradientColors={{
                    mainLeft: colors.white,
                    mainRight: colors.white,
                    hoverLeft: colors.grey.light,
                    hoverRight: colors.grey.light,
                  }}
                  clickedHandler={() => {
                    document.getElementById("contact-section")?.scrollIntoView()
                  }}
                  styles={{ width: "9rem", marginTop: "0.75rem" }}
                />
              </Text>
            </StyledText>
          </TextContainer>
        )}
      </StyledFlex>
    </StyledBackgroundBox>
  )
}

export default HeaderBanner
