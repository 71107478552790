import React, { FunctionComponent } from "react"
import { Flex, Heading, Text } from "@chakra-ui/react"
import { colors } from "../../theme/theme"
import Tile from "./Tile"

export const FourStepsTiles: FunctionComponent = ({}) => {
  return (
    <>
      <Heading
        as="h1"
        textAlign="center"
        fontSize="1.75rem"
        padding="1.5rem 0 0.25rem 0"
        fontWeight="normal"
        color={colors.grey.dark}
      >
        <b>4 Steps</b> to Make Your <b>Website</b> a <b>Reality</b>
      </Heading>
      <Text
        as="h2"
        textAlign="center"
        padding="0.5rem 0 1rem 0"
        fontWeight="normal"
        color={colors.grey.mediumDark}
      >
        Looking for a new website for your small business, club, or hobby? Here
        are 4 steps how we can make it happen!
      </Text>
      <Flex width="100%" justifyContent="center" flexWrap="wrap">
        <Tile
          number={1}
          title="Meet"
          description="Let's get to know each other and talk about your website requirements."
        />
        <Tile
          number={2}
          title="Offer"
          description="I will create an offer for you with a scope of the project, designs, and predicted price. Free of charge."
        />
        <Tile
          number={3}
          title="Development"
          description="In close cooperation with you, I will develop your website and fulfill all requirements."
        />
        <Tile
          number={4}
          title="Launch"
          description="After both of us are happy with the product, we can launch the website!"
        />
      </Flex>
    </>
  )
}

export default FourStepsTiles
