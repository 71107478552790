import React, { FunctionComponent, useState } from "react"
import styled from "@emotion/styled"
import {
  cardRadius,
  colors,
  hoverScaleEffect,
  shadows,
} from "../../theme/theme"
import { Flex, Heading, Text, Image, Box } from "@chakra-ui/react"
import { Link } from "gatsby"
import LoadingSkeleton from "../LoadingSkeleton"

const imageHeightNum = 16
const imageHeight = imageHeightNum + "rem"
const imageWidth = "14rem"

const StyledLink = styled(Link)`
  width: ${imageWidth};
  overflow: hidden;
`

const TextContainer = styled.div`
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: clip;
`

const StyledText = styled(Text)`
  margin: 0.5rem 0;
  line-height: 1.35rem;
  color: ${colors.grey.mediumDark};
`

const ImageContainer = styled(Flex)`
  height: ${imageHeight};
  width: ${imageWidth};
  overflow: hidden;
`

const StyledContainer = styled.div`
  height: calc(${imageHeight} * 1.5);
  border-radius: ${cardRadius};
  overflow: hidden;
  ${hoverScaleEffect}
  background-color: ${colors.grey.light};
  width: ${imageWidth};
  overflow: hidden;

  :hover {
    box-shadow: ${shadows.containerShadow};
    background-color: ${colors.white};
  }
`

interface IProjectCardProps {
  image: string
  title: string
  description: string
  link: string
}

export const ProjectCard: FunctionComponent<IProjectCardProps> = ({
  image,
  title,
  description,
  link,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  return (
    <Box
      style={{
        margin: "1rem 1rem",
        width: imageWidth,
      }}
    >
      <StyledLink to={link} target="_blank" title={title}>
        <StyledContainer>
          <ImageContainer>
            <Image
              src={image}
              title={title}
              width="100%"
              height={imageHeight}
              objectFit="cover"
              style={{
                display: `${imageLoaded ? "" : "none"}`,
                marginTop: "-1px",
              }}
              onLoad={() => {
                setImageLoaded(true)
              }}
            />
            {!imageLoaded && (
              <LoadingSkeleton
                height={imageHeight}
                width="100%"
                radius="cardRadius"
              />
            )}
          </ImageContainer>
          <TextContainer style={{ height: "11.4rem", overflow: "hidden" }}>
            <Heading as="h2" size="sm" color={colors.grey.dark}>
              {title}
            </Heading>
            <StyledText>{description}</StyledText>
          </TextContainer>
        </StyledContainer>
      </StyledLink>
    </Box>
  )
}

export default ProjectCard
